import {
  createStore
} from "vuex";
import createPersistedState from "vuex-persistedstate";
import company from "./modules/company";

export default createStore({
  state: {},
  stateUser: {
    curUser: null, // Выбранный пользователь
  },
  mutations: {
    setUser(stateUser, user) {
      stateUser.curUser = user;
    },
  },
  getters: {},
  actions: {},
  modules: {
    company,
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    paths: ['company.curCompany'], 
    key: '__company'
  })],
});