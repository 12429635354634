<template>
  <div v-show="loading" class="spiner-wrapper">
    <ProgressSpinner animationDuration=".5s" />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.spiner-wrapper {
  min-width: 100vw;
  height: calc(100vh - 120px);
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 10004;
  background-color: var(--primaryTextColor);
  display: none;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: flex;
  }
  .p-progress-spinner-svg {
    width: 70px;
  }
  .p-progress-spinner-circle {
    stroke: var(--base) !important;
    stroke-width: 5px;
    animation: none;
  }
}
</style>