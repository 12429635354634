import api from "@/api/index";
import router from "@/router";
import {
  setStoreCompanyList
} from '@/api/company.js'
export default {
  state: {
    curCompany: null, // Выбранная компания
    companyList: [], // Список компаний
  },
  mutations: {
    setCurCompany(state, cur) {
      state.curCompany = cur;
    },
    setCompanyList(state, companys) {
      state.companyList = companys;
    },
  },
  getters: {
    company(state) {
      return state.curCompany;
    },
    companyArray(state) {
      return state.companyList;
    },
  },
  actions: {
    getCompanyList(ctx) {
      // ctx.commit("setCompanyList", null);
      // api
      //   .get("/company/list")
      //   .then((r) => {
      //     // ctx.commit("setCompanyList", r.data);
      //     if (
      //       JSON.stringify(this.state.company.companyList) !==
      //       JSON.stringify(r.data)
      //     ) {
      //       ctx.commit("setCompanyList", r.data);
      //     }

      //     if (
      //       this.state.company.curCompany == null ||
      //       this.state.company.curCompany == undefined || !this.state.company.curCompany
      //     ) {
      //       ctx.commit("setCurCompany", r.data[0]);
      //     }
      //     if (
      //       JSON.stringify(this.state.company.curCompany) !==
      //       JSON.stringify(
      //         r.data.filter(
      //           (el) => el.id !== this.state.company.curCompany.id
      //         )[0]
      //       )
      //     ) {
      //       ctx.commit(
      //         "setCurCompany",
      //         r.data.filter(
      //           (el) => el.id === this.state.company.curCompany.id
      //         )[0]
      //       );
      //     }
      //   })
      //   .catch((err) => {
      //     if (err.request.status === 0) {
      //       that.$toast.add({
      //         severity: "warn",
      //         summary: `Нет связи с сервером ${err.request.status}`,
      //         detail: "Попробуйте позднее!",
      //         life: 5000,
      //       });
      //     } else if (err.request.status == 403) {
      //       that.$toast.add({
      //         severity: "warn",
      //         summary: `Время сессии истекло`,
      //         detail: "Требуется авторизация",
      //         life: 5000,
      //       });
      //       window.location.href = window.location.origin + "/Login";
      //     } else if (err.request.status == 404) {
      //       that.$toast.add({
      //         severity: "warn",
      //         summary: `Время сессии истекло`,
      //         detail: "Требуется авторизация",
      //         life: 5000,
      //       });
      //     } else {
      //       that.$toast.add({
      //         severity: "error",
      //         summary: `Неизвестная ошибка ${err.request.status}`,
      //         detail: "Попробуйте позднее!",
      //         life: 5000,
      //       });
      //     }
      //   });
    },
    GET_COMPANY_LIST_FROM_API(ctx) {
     setStoreCompanyList(); 
 
    }
  },
};