/** 
 * Добавление эмодзи. 

 * @param {string} name - Через запятую нужно прокинуть ключевые слова.

 * @param {string} - Ключевые слова: 
'like' - 👍, 
'lock' - 🔐, 
'error' - 🛑🚫⛔️, 
'access' - ✅👌,  
'ok' - 🆗, 
'goodjob' - 😎, 
'trash' - 🗑,
 неккоректное значение - 🤕👎
 Пример для вызова setEmoji('like, lock'), ответ: 👍🔐

 * @return {string}  Возвращает строку с эмодзи.

 * @author  Alexandr Bukhanov

 * @version  0.0.1

 */
export function setEmoji(name) {
  // String.fromCodePoint(0x1f44d)
  let arr = name.split(",").map((el) => el.trim());
  let newArr = [];
  arr.forEach((element) => {
    switch (element) {
      case "like":
        newArr.push("👍");
        break;
      case "lock":
        newArr.push("🔐");
        break;
      case "error":
        newArr.push("🛑🚫⛔️");
        break;
      case "access":
        newArr.push("✅👌");
        break;
      case "ok":
        newArr.push("🆗");
        break;
      case "goodjob":
        newArr.push("😎");
        break;
      case "trash":
        newArr.push("🗑");
        break;
      default:
        newArr.push("🤕👎");
        break;
    }
  });
  return newArr.join("");
}
