import api from "@/api/index";
import { setEmoji } from "@/handlers/emoji";

export function getVersion() {
  api
    .get("main/config?code=ver_lk")
    .then((r) => {
      if (localStorage.getItem("version") === null) {
        localStorage.setItem("version", r.data.value);
        location.reload();
      }
      if (localStorage.getItem("version") !== r.data.value) {
        localStorage.setItem("version", r.data.value);
        location.reload();
      } else {
        return;
      }
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}
