<template>
  <div class="n-progress-bar" v-if="loading">
    <slot><ProgressBar mode="indeterminate" /></slot>
  </div>
  <div style="height: 0.5rem" v-else>&nbsp;</div>
</template> 
<script>
export default {
  name: "LoadingProgress",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
</style>