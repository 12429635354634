<template>
  <div
    class="paginator flex p-0 gap-2 justify-content-center align-items-stretch"
  >
    <div>
      <Button
        :disabled="isDisabled"
        @click="firstPage"
        style="width: 2rem"
        class="p-button-sm py-1 px-1"
        icon="pi pi-angle-double-left"
      />
    </div>
    <div>
      <Button
        :disabled="isDisabled"
        @click="decrementPage"
        style="width: 2rem"
        class="p-button-sm py-1 px-1"
        icon="pi pi-angle-left"
      />
    </div>
    <div class="flex gap-2 align-items-center">
      <InputText
        type="number"
        :disabled="isDisabled"
        @input="$event.target.value.replace(/\D/g, '')"
        @blur="pageBlur($event)"
        @keypress.enter="pageBlur($event)"
        :value="page > allPage ? allPage : page"
        class="p-1 text-center text-sm"
        style="width: 3rem"
      />
      <small>из {{ Boolean(totalRecords) ? allPage : 1 }}</small>
    </div>
    <div>
      <Button
        :disabled="isDisabled"
        @click="incrementPage"
        style="width: 2rem"
        class="p-button-sm py-1 px-1"
        icon="pi pi-angle-right"
      />
    </div>
    <div>
      <Button
        :disabled="isDisabled"
        @click="lastPage"
        style="width: 2rem"
        class="p-button-sm py-1 px-1"
        icon="pi pi-angle-double-right"
      />
    </div>
    <!-- <InputText
        type="number"
        @blur="rowsBlur($event)"
        @input="$event.target.value.replace(/\D/g, '')"
        @keypress.enter="rowsBlur($event)"
        :value="rowsValue"
        class="p-1 text-center text-sm"
        style="width: 3rem"
        max="1000"
        min="1"
      /> -->
    <select
      v-if="Boolean(rowsPerPageOptions)"
      class="px-1 py-1 text-sm border-round cursor-pointer bg-white"
      v-model.number="rowsValue"
      @change="rowsBlur($event)"
      :disabled="globalDisabel"
      style="width: 70px;"
    >
      <option v-for="(item, i) in rowsPerPageOptions" :key="i" :value="item">
        {{ item }}
      </option>
    </select>
    <!-- <small>кол-во траназкций на странице (меньше 1000)</small> -->
  </div>
</template>

<script>
export default {
  props: {
    totalRecords: {
      type: Number,
      requaried: false,
      default: 1,
    },
    rows: {
      type: Number,
      requaried: true,
    },
    page: {
      type: Number,
      requaried: true,
    },
    rowsPerPageOptions: {
      type: Number,
      requaried: false,
    },
    globalDisabel: {
      type: Boolean,
      requaried: true,
    },
  },
  data() {
    return {
      totalValue: this.totalRecords,
      pageValue: this.page,
      rowsValue: this.rows,
    };
  },
  methods: {
    setData() {
      this.$emit("page", {
        page: this.pageValue,
        rows: this.rowsValue,
      });
    },
    incrementPage() {
      if (this.pageValue >= this.allPage) {
        this.pageValue = this.allPage;
      } else {
        this.pageValue++;
      }
      if (this.page !== this.pageValue) {
        this.setData();
      }
    },
    decrementPage() {
      this.pageValue = this.page > this.allPage ? this.allPage : this.page;
      if (this.pageValue <= 1) {
        this.pageValue = 1;
      } else {
        this.pageValue--;
      }
      if (this.page !== this.pageValue) {
        this.setData();
      }
    },
    firstPage() {
      this.pageValue = 1;
      if (this.page !== this.pageValue) {
        this.setData();
      }
    },
    lastPage() {
      this.pageValue = this.allPage;
      if (this.page !== this.pageValue) {
        this.setData();
      }
    },
    pageBlur(e) {
      //   console.log(e.target.value);
      this.pageValue = Number(e.target.value.replace(/\D/g, ""));
      if (this.pageValue >= this.allPage) {
        this.pageValue = this.allPage;
      }
      if (this.pageValue <= 1) {
        this.pageValue = 1;
      }
      if (this.page !== this.pageValue) {
        this.setData();
      }
    },
    rowsBlur(e) {
      //   console.log(e.target.value);
      // this.rowsValue = Number(e.target.value.replace(/\D/g, ""));
      // if (this.rowsValue > 1000) {
      //   this.rowsValue = 1000;
      // }
      if (this.rowsValue < 1) {
        this.rowsValue = 1;
      }
      if (this.rows !== this.rowsValue) {
        this.setData();
      }
      //   console.log(this.rowsValue);
    },
    select(e) {
      console.log(e);
    },
    // onlyNum(e) {
    //   this.pageValue = e.target.value.replace(/\D/g, "");
    // },
  },
  computed: {
    allPage() {
      return Math.ceil(this.totalRecords / this.rowsValue);
    },
    isDisabled() {
      return (
        this.allPage === 1 || this.totalRecords === null || this.globalDisabel
      );
    },
  },
};
</script>

<style lang="scss">
.paginator {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>