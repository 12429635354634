import api from "@/api/index";
import store from "@/store";
import { mapActions } from "vuex";

export function getProfile(that) {
  api
    .get("user/get")
    .then((r) => {
      if (
        !store.state.curUser ||
        store.state.curUser.phone != r.data.phone ||
        JSON.stringify(r.data) !== JSON.stringify(store.state.curUser)
      ) {
        that.profiles = r.data;
        store.commit("setUser", r.data); 
        // if (store.state.company.companyList["0"] !== undefined) {
        //   store.commit("setCurCompany", store.state.company.companyList["0"]);
        // }
      }

      that.loading = false;
    })
    .catch((err) => {
      if (err.request.status === 0) {
        that.$toast.add({
          severity: "warn",
          summary: `Нет связи с сервером ${err.request.status}`,
          detail: "Попробуйте позднее!",
          life: 5000,
        });
      } else if (err.request.status == 403) {
        that.$toast.add({
          severity: "warn",
          summary: `Время сессии истекло`,
          detail: "Требуется авторизация",
          life: 5000,
        });
        that.$router.push("/login");
      } else {
        that.$toast.add({
          severity: "error",
          summary: `Неизвестная ошибка ${err.request.status}`,
          detail: "Попробуйте позднее!",
          life: 5000,
        });
      }
    });
}
